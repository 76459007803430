import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "make-it-understandable---meta-info"
    }}>{`Make it understandable - meta info`}</h1>
    <Vimeo id={431832887} mdxType="Vimeo" />
    <p>{`You've come so far! There's a US map and a histogram. They're blue and shiny
and you look at them and you go `}<em parentName="p">{`"Huh?"`}</em>{`.`}</p>
    <p>{`The key to a good data visualization is telling users what it means. An easy
way to do that is a good title and description. Just tell them. The picture
supports the words, the words explain the picture.`}</p>
    <p>{`Let's add those words.`}</p>
    <p>{`We're adding a dynamic title and description, and a median line on the
histogram. The text is dynamic because we're adding user controls later, and we
want the pictures and the words to stay in sync.`}</p>
    <p>{`At the end of this section, you'll have a full visualization of the shortened
dataset.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/dataviz-without-controls.png",
        "alt": "Full visualization without user controls"
      }}></img></p>
    <h2 {...{
      "id": "dynamic-title"
    }}>{`Dynamic title`}</h2>
    <Vimeo id={431833041} mdxType="Vimeo" />
    <p>{`We begin with the title because it shows up first.`}</p>
    <p>{`We start with an import in `}<inlineCode parentName="p">{`App.js`}</inlineCode>{` and add it to the render method. You know
the drill 😄`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
import CountyMap from "./components/CountyMap"
import Histogram from "./components/Histogram"
// Insert the line(s) between here...
import { Title } from "./components/Meta"
// ...and here.

function App() {
    const [datasets, setDatasets] = useState({
        techSalaries: [],
        medianIncomes: [],
        countyNames: [],
        usTopoJson: null,
        USstateNames: null,
    });
    // Insert the line(s) between here...
    const [filteredBy, setFilteredBy] = useState({
        USstate: "*",
        year: "*",
        jobTitle: "*",
    });
    // ...and here.
  }

  // ...

    return (
      <div className="App container">
        // Insert the line(s) between here...
        <Title data={filteredSalaries} filteredBy={filteredBy} />
        // ...and here. // ...
      </div>
    )
}
`}</code></pre>
    <p>{`Ok, I lied. We did a lot more than just imports and adding to render.`}</p>
    <p>{`We also set up the `}<inlineCode parentName="p">{`App`}</inlineCode>{` component for future user-controlled data filtering.
The `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` state tells us what the user is filtering by – 3
options: `}<inlineCode parentName="p">{`USstate`}</inlineCode>{`, `}<inlineCode parentName="p">{`year`}</inlineCode>{`, and `}<inlineCode parentName="p">{`jobTitle`}</inlineCode>{`. We set them to "everything" by
default.`}</p>
    <p>{`We added them now so that we can immediately write our `}<inlineCode parentName="p">{`Title`}</inlineCode>{` component in a
filterable way. No need to make changes later.`}</p>
    <p>{`As you can see, `}<inlineCode parentName="p">{`Title`}</inlineCode>{` takes `}<inlineCode parentName="p">{`data`}</inlineCode>{` and `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` props.`}</p>
    <h3 {...{
      "id": "get-the-usstatesmap-file"
    }}>{`Get the USStatesMap file`}</h3>
    <p>{`You need the `}<inlineCode parentName="p">{`USStatesMap`}</inlineCode>{` file.`}</p>
    <p>{`It's a big dictionary that translates US state codes to full names. You can
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/react-d3-walkthrough-livecode/raw/3b1052fe3f70ab12142e5aac684b95ed430b12b2/src/components/Meta/USStatesMap.js"
      }}>{`get it from Github`}</a>{`
and save it as `}<inlineCode parentName="p">{`components/Meta/USStatesMap.js`}</inlineCode>{`.`}</p>
    <p>{`We'll use it when creating titles and descriptions.`}</p>
    <h3 {...{
      "id": "implement-title"
    }}>{`Implement Title`}</h3>
    <Vimeo id={431834731} mdxType="Vimeo" />
    <p>{`We're building two types of titles based on user selection. If both `}<inlineCode parentName="p">{`years`}</inlineCode>{` and
`}<inlineCode parentName="p">{`US state`}</inlineCode>{` were selected, we return
`}<inlineCode parentName="p">{`In {US state}, the average {job title} paid \${mean}/year in {year}`}</inlineCode>{`. If not,
we return `}<inlineCode parentName="p">{`{job title} paid \${mean}/year in {state} in {year}`}</inlineCode>{`.`}</p>
    <p>{`I know, it's confusing. They look like the same sentence turned around. Notice
the `}<em parentName="p">{`and`}</em>{`. First option when `}<em parentName="p">{`both`}</em>{` are selected, second when either/or.`}</p>
    <p>{`We start with imports, a stub, and an export.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Meta.js
import React, { Component } from "react"
import { scaleLinear } from "d3-scale"
import { mean as d3mean, extent as d3extent } from "d3-array"

import USStatesMap from "./USStatesMap"

export const Title = ({ filteredSalaries, filteredBy }) => {}
`}</code></pre>
    <p>{`We import only what we need from D3's `}<inlineCode parentName="p">{`d3-scale`}</inlineCode>{` and `}<inlineCode parentName="p">{`d3-array`}</inlineCode>{` packages. I
consider this best practice until you're importing so much that it gets messy
to look at.`}</p>
    <h4 {...{
      "id": "the-helper-methods"
    }}>{`The helper methods`}</h4>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`yearsFragment`}</inlineCode>{` describes the selected year`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`USstateFragment`}</inlineCode>{` describes the selected US state`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`jobTitleFragment`}</inlineCode>{` describes the selected job title`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`format`}</inlineCode>{` returns a number formatter`}</li>
    </ol>
    <p>{`We can implement `}<inlineCode parentName="p">{`yearsFragment`}</inlineCode>{`, `}<inlineCode parentName="p">{`USstateFragment`}</inlineCode>{`, and `}<inlineCode parentName="p">{`format`}</inlineCode>{` in one code
sample. They're short.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Meta.js
export const Title = ({ filteredSalaries, filteredBy }) => {
    function yearsFragment() {
        const year = filteredBy.year;
        return year === "*" ? "" : \`in \${year}\`;
    }

    function USstateFragment() {
        const USstate = filteredBy.USstate;
        return USstate === "*" ? "" : USStatesMap[USstate.toUpperCase()];
    }

    function format() {
        return scaleLinear()
            .domain(d3extent(filteredSalaries, (d) => d.base_salary))
            .tickFormat();
    }
`}</code></pre>
    <p>{`In both `}<inlineCode parentName="p">{`yearsFragment`}</inlineCode>{` and `}<inlineCode parentName="p">{`USstateFragment`}</inlineCode>{`, we get the appropriate value
from Title's `}<inlineCode parentName="p">{`filteredBy`}</inlineCode>{` prop, then return a string with the value or an empty
string.`}</p>
    <p>{`We rely on D3's built-in number formatters to build `}<inlineCode parentName="p">{`format`}</inlineCode>{`. Linear scales
have the one that turns `}<inlineCode parentName="p">{`10000`}</inlineCode>{` into `}<inlineCode parentName="p">{`10,000`}</inlineCode>{`. Tick formatters don't work well
without a `}<inlineCode parentName="p">{`domain`}</inlineCode>{`, so we define it. We don't need a range because we never use
the scale itself.`}</p>
    <p><inlineCode parentName="p">{`format`}</inlineCode>{` returns a function, which makes it a
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Higher-order_function"
      }}>{`higher order function`}</a>{`.
Being a getter makes it really nice to use: `}<inlineCode parentName="p">{`this.format()`}</inlineCode>{`. Looks just like a
normal function call 😄`}</p>
    <p>{`The `}<inlineCode parentName="p">{`jobTitleFragment`}</inlineCode>{` is conceptually no harder than `}<inlineCode parentName="p">{`yearsFragment`}</inlineCode>{`
and `}<inlineCode parentName="p">{`USstateFragment`}</inlineCode>{`, but it comes with a few more conditionals.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Meta.js

export const Title = ({ filteredSalaries, filteredBy }) => {
  // ...
  function jobTitleFragment() {
    const { jobTitle, year } = filteredBy
    let title = ""

    if (jobTitle === "*") {
      if (year === "*") {
        title = "The average H1B in tech pays"
      } else {
        title = "The average tech H1B paid"
      }
    } else {
      title = \`Software \${jobTitle}s on an H1B\`

      if (year === "*") {
        title += " make"
      } else {
        title += " made"
      }
    }

    return title
  }
  // ...
}
`}</code></pre>
    <p>{`We're dealing with the `}<inlineCode parentName="p">{`(jobTitle, year)`}</inlineCode>{` combination. Each influences the
other when building the fragment for a total 4 different options.`}</p>
    <h4 {...{
      "id": "the-render"
    }}>{`The render`}</h4>
    <Vimeo id={431835247} mdxType="Vimeo" />
    <p>{`We put all this together in the `}<inlineCode parentName="p">{`render`}</inlineCode>{` method. A conditional decides which of
the two situations we're in, and we return an `}<inlineCode parentName="p">{`<h2>`}</inlineCode>{` tag with the right text.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Title.js
export const Title = ({ filteredSalaries, filteredBy }) => {
  // ...
  const mean = format()(d3mean(filteredSalaries, (d) => d.base_salary))

  let title

  if (yearsFragment() && USstateFragment()) {
    title = (
      <h2>
        In {USstateFragment()}, {jobTitleFragment()}\${mean}/year{" "}
        {yearsFragment()}
      </h2>
    )
  } else {
    title = (
      <h2>
        {jobTitleFragment()} \${mean}/year
        {USstateFragment() ? \`in \${USstateFragment()}\` : ""}
        {yearsFragment()}
      </h2>
    )
  }
  return title
}
`}</code></pre>
    <p>{`Calculate the mean value using `}<inlineCode parentName="p">{`d3.mean`}</inlineCode>{` with a value accessor, turn it into a
pretty number with `}<inlineCode parentName="p">{`format()`}</inlineCode>{`, then use one of two string patterns to make a
`}<inlineCode parentName="p">{`title`}</inlineCode>{`.`}</p>
    <p>{`And a title appears after a little debugging.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/dataviz-with-title.png",
        "alt": "Dataviz with title"
      }}></img></p>
    <h2 {...{
      "id": "dynamic-description"
    }}>{`Dynamic description`}</h2>
    <Vimeo id={431835684} mdxType="Vimeo" />
    <p>{`You know what? The dynamic description component is pretty much the same as the
title. It's just longer and more complex and uses more code. It's interesting,
but not super relevant to the topic of this book.`}</p>
    <p>{`So rather than explain it all here, I'm going to give you a link to the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/ebbe0d490496fa2186ba8908264cfe931dc7165f"
      }}>{`diff on Github`}</a></p>
    <p>{`We use the same approach as before:`}</p>
    <ol>
      <li parentName="ol">{`Add imports in `}<inlineCode parentName="li">{`App.js`}</inlineCode></li>
      <li parentName="ol">{`Add component to `}<inlineCode parentName="li">{`App`}</inlineCode>{` render`}</li>
      <li parentName="ol">{`Implement component in `}<inlineCode parentName="li">{`components/Meta.js`}</inlineCode></li>
      <li parentName="ol">{`Use getters for sentence fragments`}</li>
      <li parentName="ol">{`Play with conditionals to construct different sentences`}</li>
    </ol>
    <p>{`142 lines of mundane code.`}</p>
    <p>{`All the interesting complexity goes into finding the richest city and county.
That part looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/Meta/Description.js
get countyFragment() {
  const byCounty = _.groupBy(this.props.data, 'countyID'),
        medians = this.props.medianIncomesByCounty;

  let ordered = _.sortBy(
      _.keys(byCounty)
       .map(county => byCounty[county])
       .filter(d => d.length/this.props.data.length > 0.01),
      items => d3mean(items,
                      d => d.base_salary) - medians[items[0].countyID][0].medianIncome);

  let best = ordered[ordered.length-1],
      countyMedian = medians[best[0].countyID][0].medianIncome;

  // ...
}
`}</code></pre>
    <p>{`We group the dataset by county, then sort counties by their income delta. We
look only at counties that are bigger than 1% of the entire dataset. And we
define income delta as the difference between a county's median household
income and the median tech salary in our dataset.`}</p>
    <p>{`This code is not super efficient, but it gets the job done. We could optimize
by just looking for the max value, for example.`}</p>
    <p>{`Similar code handles finding the best city.`}</p>
    <h3 {...{
      "id": "render-the-description"
    }}>{`Render the description`}</h3>
    <p>{`I recommend copying the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/ebbe0d490496fa2186ba8908264cfe931dc7165f"
      }}><inlineCode parentName="a">{`Description`}</inlineCode>{` component from GitHub`}</a>{`.
Most of it has little to do with React and data visualization. It's all about
combining sentence fragments based on props.`}</p>
    <p>{`You then render the Description like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/App.js

import { Title, Description } from "./components/Meta"

// ..
;<Description
  data={filteredSalaries}
  allData={techSalaries}
  filteredBy={filteredBy}
  medianIncomesByCounty={this.state.medianIncomesByCounty}
/>
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/2018/dataviz-with-description.png",
        "alt": "Dataviz with Title and Description"
      }}></img></p>
    <h2 {...{
      "id": "overlay-a-median-household-line"
    }}>{`Overlay a median household line`}</h2>
    <p>{`Here's a more interesting component: the median dotted line. It shows a direct
comparison between the histogram's distribution and the median household income
in an area. I'm not sure people understand it at a glance, but I think it's
cool.`}</p>
    <p>{`We're using a quick approach where everything fits into a functional React
component. It's great for small components like this.`}</p>
    <h3 {...{
      "id": "step-1-appjs"
    }}>{`Step 1: App.js`}</h3>
    <Vimeo id={431835935} mdxType="Vimeo" />
    <p>{`Inside `}<inlineCode parentName="p">{`src/App.js`}</inlineCode>{`, we first have to add an import, then extract the median
household value from state, and in the end, add `}<inlineCode parentName="p">{`MedianLine`}</inlineCode>{` to the render
method.`}</p>
    <p>{`Let's see if we can do it in a single code block 😄`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/App.js
import Histogram from './components/Histogram';
import { Title, Description, GraphDescription } from './components/Meta';
// Insert the line(s) between here...
import MedianLine from './components/MedianLine';
// ...and here.

function App() {
        // ...
        let zoom = null,
            // Insert the line(s) between here...
            medianHousehold = medianIncomesByUSState['US'][0]
                                  .medianIncome;
            // ...and here.

        return (
            // ...
            <svg width="1100" height="500">
                <CountyMap // ... />
                <Histogram // ... />
                // Insert the line(s) between here...
                <MedianLine data={filteredSalaries}
                            x={500}
                            y={10}
                            width={600}
                            height={500}
                            bottomMargin={5}
                            median={medianHousehold}
                            value={d => d.base_salary} />
                // ...and here.
            </svg>
        )
}
`}</code></pre>
    <p>{`You probably don't remember `}<inlineCode parentName="p">{`medianIncomesByUSState`}</inlineCode>{` anymore. We set it up when `}<a parentName="p" {...{
        "href": "tech-salaries/load-parse-data#step-4-tie-the-datasets-together"
      }}>{`tying datasets together`}</a>{`.
It groups our salary data by US state.`}</p>
    <p>{`See, using good names helps 😄`}</p>
    <p>{`When rendering `}<inlineCode parentName="p">{`MedianLine`}</inlineCode>{`, we give it sizing and positioning props, the
dataset, a `}<inlineCode parentName="p">{`value`}</inlineCode>{` accessor, and the median value to show. We could make it
smart enough to calculate the median, but the added flexibility of a prop felt
right.`}</p>
    <h3 {...{
      "id": "step-2-medianline"
    }}>{`Step 2: MedianLine`}</h3>
    <Vimeo id={431836837} mdxType="Vimeo" />
    <p>{`The `}<inlineCode parentName="p">{`MedianLine`}</inlineCode>{` component looks similar to what you've seen so far. Some
imports, a `}<inlineCode parentName="p">{`constructor`}</inlineCode>{` that sets up D3 objects, an `}<inlineCode parentName="p">{`updateD3`}</inlineCode>{` method that
keeps them in sync, and a `}<inlineCode parentName="p">{`render`}</inlineCode>{` method that outputs SVG.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/MedianLine.js

import React from "react"
import * as d3 from "d3"

const MedianLine = ({
  data,
  value,
  width,
  height,
  x,
  y,
  bottomMargin,
  median,
}) => {}

export default MedianLine
`}</code></pre>
    <p>{`We have some imports, a functional `}<inlineCode parentName="p">{`MedianLine`}</inlineCode>{` component that takes our props,
and an export. It should cause an error because it's not returning anything.`}</p>
    <p>{`Everything we need to render the line, fits into this function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/components/MedianLine.js

const MedianLine = (
  {
    // ...
  }
) => {
  const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, value)])
      .range([height - y - bottomMargin, 0]),
    line = d3.line()([
      [0, 5],
      [width, 5],
    ])

  const medianValue = median || d3.median(data, value)

  const translate = \`translate(\${x}, \${yScale(medianValue)})\`,
    medianLabel = \`Median Household: $\${yScale.tickFormat()(median)}\`

  return (
    <g className="mean" transform={translate}>
      <text
        x={width - 5}
        y="0"
        textAnchor="end"
        style={{ background: "purple" }}
      >
        {medianLabel}
      </text>
      <path d={line} />
    </g>
  )
}
`}</code></pre>
    <p>{`We start with a scale for vertical positioning – `}<inlineCode parentName="p">{`yScale`}</inlineCode>{`. It's linear, takes
values from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`max`}</inlineCode>{`, and translates them to pixels less some margin. For
the `}<inlineCode parentName="p">{`medianValue`}</inlineCode>{`, we use props, or calculate our own, if needed. Just like I
promised.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`translate`}</inlineCode>{` SVG transform helps us position our line and label. We use it all
to return a `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` grouping element containing a `}<inlineCode parentName="p">{`<text>`}</inlineCode>{` for our label, and a
`}<inlineCode parentName="p">{`<path>`}</inlineCode>{` for the line.`}</p>
    <p>{`Building the `}<inlineCode parentName="p">{`d`}</inlineCode>{` attribute for the path, that's interesting. We use a `}<inlineCode parentName="p">{`line`}</inlineCode>{`
generator from D3.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`line = d3.line()([
  [0, 5],
  [width, 5],
])
`}</code></pre>
    <p>{`It comes from the `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-shape#lines"
      }}>{`d3-shape`}</a>{` package and
generates splines, or polylines. By default, it takes an array of points and
builds a line through all of them. A line from `}<inlineCode parentName="p">{`[0, 5]`}</inlineCode>{` to `}<inlineCode parentName="p">{`[width, 5]`}</inlineCode>{` in our
case.`}</p>
    <p>{`That makes it span the entire width and leaves 5px for the label. We're using a
`}<inlineCode parentName="p">{`transform`}</inlineCode>{` on the entire group to vertically position the final element.`}</p>
    <p>{`Remember, we already styled `}<inlineCode parentName="p">{`medianLine`}</inlineCode>{` when we built
`}<a parentName="p" {...{
        "href": "/tech-salaries/salaries-histogram#step-2-css-changes"
      }}>{`histogram styles`}</a>{`
earlier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-{.css",
        "metastring": "caption=\"Histogram css\"}",
        "caption": "\"Histogram",
        "css\"}": true
      }}>{`.mean text {
    font: 11px sans-serif;
    fill: grey;
}

.mean path {
    stroke-dasharray: 3;
    stroke: grey;
    stroke-width: 1px;
}
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`stroke-dasharray`}</inlineCode>{` is what makes it dashed. `}<inlineCode parentName="p">{`3`}</inlineCode>{` means each `}<inlineCode parentName="p">{`3px`}</inlineCode>{` dash is
followed by a `}<inlineCode parentName="p">{`3px`}</inlineCode>{` blank. You can use
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray"
      }}>{`any pattern you like`}</a>{`.`}</p>
    <p>{`You should see a median household salary line overlaid on your histogram.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/Swizec/react-d3js-es6-ebook/2018-version/manuscript/resources/images/es6v2/dataviz-with-everything.png",
        "alt": "Median line over histogram"
      }}></img></p>
    <p>{`Almost everyone in tech makes more than an entire median household. Crazy, huh?
I think it is.`}</p>
    <p>{`If that didn't work, consult the
`}<a parentName="p" {...{
        "href": "https://github.com/Swizec/reactdataviz-project/commit/ebbe0d490496fa2186ba8908264cfe931dc7165f"
      }}>{`diff on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      